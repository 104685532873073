export interface PromoDetailDataModel {
  createdBy: string
  createdDate: string
  description: string
  hasCoupons: boolean
  id: string
  isActive: boolean
  isExclusive: boolean
  maxPersonalUsageCount: number
  maxUsageCount: number
  maxUsageOnOrder: number
  modifiedBy: string
  modifiedDate: string
  name: string
  outerId: string
  priority: 0
  startDate: string
  store: string
  storeIds: []
  type: string
}

export type PromoGridDataModel = PromoDetailDataModel;

export interface PromoModel {
  promoDetail: PromoDetailDataModel
  promoGrid: PromoGridDataModel[]
}

export enum PromoActionType {
  GetPromoDetail = '⌘➝App➝Promo➝Promo-Detail➝GetPromoDetail',
  PostPromoGrid = '⌘➝App➝Promo➝PostPromoGrid'
}

export type PromoAction = {
  data: PromoDetailDataModel
  type: PromoActionType.GetPromoDetail
} |
{
  data: PromoGridDataModel[]
  type: PromoActionType.PostPromoGrid
};
